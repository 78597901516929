import React from "react"
import * as PropTypes from "prop-types"
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { rhythm } from "../../utils/typography"
import Layout from "../../components/layout"

const propTypes = {
  data: PropTypes.object.isRequired,
}

function getLangDir(locale) {
  const langDir = locale === 'ja' ? 'ja' : 'en';
  return langDir;
}

class TermsPage extends React.Component {
  render() {
    //const enNewsEdges = this.props.data.en.edges
    return (
      <Layout data={this.props.data} location={this.props.location}>
        <Helmet>
          <title>プライバシーポリシー │ Cookin’Burger</title>
          <meta name="description" content="New PlayMining Platform Game! Set to launch in Spring 2022! A multi-tasking cooking game!" />
        </Helmet>
        <div className="l-main">
          <div className="l-term-section">
            <div className="section-inner_m">
              <div className="l-term-section-box">
                <div className="l-term-section-title wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                プライバシーポリシー
                </div>
                <p className="l-term-section-cap wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="0.7s" data-wow-offset="0">
                    第1条（個人情報）<br />
                    「個人情報」とは、個人情報の保護に関する法律（平成15年法律第57号、以下「個人情報保護法」といいます。）にいう「個人情報」を指し、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別できるもの又は個人識別符号が含まれるものを指します。<br />
                    <br />
                    第2条（個人情報の取得と利用）<br />
                    当社は、以下の目的に必要な範囲で、ご本人の個⼈情報を取得し、取得した情報を利用させていただきます。以下の⽬的の範囲を超えて個⼈情報を利⽤する場合には、事前に適切な⽅法でご本人からの同意を得るものとします。<br />
                    （1）当社のサービス（以下「本サービス」といいます。）を提供するため<br />
                    （2）本サービスの内容を改良・改善し、又は新サービスを開発するため<br />
                    （3）本サービスの新機能、更新情報、キャンペーン等及び当社が提供する他のサービスのご案内（電子メールの送付を含む）のため<br />
                    （4）メンテナンス、重要なお知らせなど必要に応じたご連絡のため<br />
                    （5）本サービスに関する利用者からのお問い合わせに回答するため（本人確認を行うことを含む）<br />
                    （6）本サービスの利用状況を利用者にご報告するため<br />
                    （7）本サービスに関するアンケート・取材等のご協力依頼や各種イベントへのご参加をお願いし、又はその結果などをご報告するため<br />
                    （8）本サービスの利用履歴等を調査・分析し、その結果を本サービスの改良・開発や広告の配信に利用するため<br />
                    （9）利用規約に違反した利用者や、不正・不当な目的で本サービスを利用しようとする利用者の特定をし、ご利用をお断りするため<br />
                    <br />
                    第3条（個人情報の管理と保護）<br />
                    個人情報の管理は、厳重に行うこととし、次に掲げる場合を除き、ご本人の同意がない限り、第三者に対しデータを開示・提供することはいたしません。 <br />また、安全性を考慮し、個人情報への不正アクセス、個人情報の紛失、破壊、改ざん及び漏えい等のリスクに対する予防並びに是正に関する対策を講じます。<br />
                    （1）人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難である場合<br />
                    （2）公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難である場合<br />
                    （3）国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合<br />
                    （4）業務を円滑に遂行するため、利用目的の達成に必要な範囲内において個人情報の取扱いの全部又は一部を委託する場合<br />
                    （5）合併その他の事由による事業の承継に伴って個人情報が提供される場合<br />
                    （6）その他法令で認められる場合<br />
                    <br />
                    第4条（共同利用）<br />
                    当社は、ユーザーの個人データを以下のとおり当社の協働企業と共同して利用することがあります。<br />
                    1 共同して利用される個人データの項目 　　<br />
                    氏名、メールアドレス、居住国、住所、電話番号、生年月日、性別、商品購入および取引履歴<br />
                    2 共同して利用する企業<br />
                    Digital Entertainment Asset Pte.Ltd.<br />
                    3 利用する者の利用目的<br />
                    当社の協働企業の商品・サービスをお知らせするため<br />
                    4 当該個人データの管理について責任を有する者の氏名又は名称<br />
                    Digital Entertainment Asset Pte.Ltd.<br />
                    <br />
                    第5条（個人情報の取扱いの委託） <br />
                    当社は、利用目的の達成に必要な範囲内において、個人情報の取扱いの全部又は一部を委託する場合がございます。この場合、当社は、委託先としての適格性を十分審査するとともに、契約にあたって守秘義務に関する事項等を定め、委託先に対する必要かつ適切な監督を行います。<br />
                    <br />
                    第6条（個人情報の開示）<br />
                    当社は、ユーザーから、個人情報保護法の定めに基づき個人情報の開示を求められたときは、ユーザーご本人からのご請求であることを確認の上で、ユーザーに対し、遅滞なく開示を行います（当該個人情報が存在しない時はその旨を通知いたします。）。ただし、個人情報保護法その他の法令により、当社が開示の義務を負わない場合は、この限りではありません。なお、個人情報の開示につきましては、手数料（１件あたり1,000円）を頂戴しておりますので、あらかじめご了承ください。<br />
                    <br />
                    第7条（個人情報の訂正及び利用停止等）<br />
                    1 当社は、ユーザーから、個人情報が真実でないという理由によって、個人情報保護法の定めに基づきその内容の訂正を求められた場合には、他の法令の規定により特別の手続が定められている場合を除き、ユーザーかご本人からのご請求であることを確認の上で遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正又は利用停止を行い、その旨をユーザーに通知します。<br />
                    2 当社は、ユーザーから、個人情報が、予め公表された利用目的の範囲を超えて取り扱われているという理由、又は不正の手段により取得されたものであるという理由により、その利用の停止又は消去（以下「利用停止等」といいます。）を求められた場合には、遅滞なく必要な調査を行い、その結果に基づき、個人情報の利用停止等を行い、その旨ご本人に通知します。ただし、個人情報の利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって、ご本人の権利利益を保護するために必要なこれに代わるべき措置をとれる場合は、この代替策を講じます。<br />
                    <br />
                    第8条（プライバシーポリシーの変更手続）<br />
                    当社は本ポリシーの内容を適宜見直し、その改善に努めます。本ポリシーの内容は、法令その他本ポリシーに別段の定めのある事項を除いて、変更することができるものとします。変更後のプライバシーポリシーは、当社所定の方法により、利用者に通知し、又は当社ウェブサイトに掲載したときから効力を生じるものとします。<br />
                    <br />
                    第9条（お問い合わせ窓口）<br />
                    ご意見、ご質問、苦情のお申出その他個人情報の取扱いに関するお問い合わせは、下記の窓口までお願いいたします。<br />
                    <br />
                    住所：神奈川県川崎市中原区小杉御殿町2-106<br />
                    株式会社バーガースタジオ<br />
                    個人情報取扱責任者：清田 貴史<br />
                    連絡先：support@burgerstudio.jp<br />
                    <br />
                    ※EUユーザーに関する特別の定め<br />
                    本ポリシーの他の定めに関わらず、EU一般データ保護規則 (以下「GDPR」といいます) が適用される法域において、当該規制に基づく当社におけるEU ユーザーの権利、及び当社の責任に関する情報は以下の通りです。<br />
                    <br />
                    (a) EU ユーザーの権利<br />
                    EU 内の 本サービスのユーザーは、当社によるユーザーの個人情報の取り扱いに関して、次の権利を有します。<br />
                    ①ユーザーのデータに関する説明及び写し<br />
                    ユーザーは、当社が保有するユーザーの情報に関する説明、及び当社による個人情報の使用方法に関する説明を求める権利を有します。<br />
                    上記に加え、当社がユーザーの情報をその同意に基づき収集した場合、又はユーザーからリクエストを受けたサービスの提供に必要であるために収集した場合、ユーザーは、当社がユーザーに関して収集した当該情報の写しの交付を受ける権利を有します。<br />
                    ②修正<br />
                    当社がユーザーに関して保有する情報が不正確であるとユーザーが確信する場合、ユーザーは自身の情報の修正を求める権利を有します。<br />
                    ③削除<br />
                    ユーザーは、いつでも、自身のアカウントの削除を求めることができます。ユーザーは、当社のウェブサイトから削除を求めることができます。当社は、法律上求められる場合、及び法律上認められる正当な事業目的による場合、ユーザーに関する特定の情報を保持できます。<br />
                    ④異議及び苦情の申し立て<br />
                    EU内のユーザーは、当社による個人データの処理について異議を申し立てる権利を有します。この個人データの処理には、プロファイリング及び自動化された意思決定によるマーケティングを目的とする処理が含まれます。異議が申し立てられた場合であっても、当社はGDPRが認める範囲において、引き続きユーザーの情報を処理することができ、当該異議によって直ちに個人データの処理について、削除その他の変更が認められるものではありません。<br />
                    <br />
                    (b) 情報の処理<br />
                    当社は、GDPR に基づき定められた根拠に基づいてEU ユーザーの情報を処理します。根拠の詳細は以下の通りです。<br />
                    ①サービスの提供のための情報の収集・使用<br />
                    当社がサービスを提供するためには、特定の情報を収集、使用する必要があります。これには、次の情報が含まれます。<br />
                    ・ユーザーアカウントの作成及び維持に必要なユーザーのプロフィール情報。ユーザーの購入及びアカウントについての連絡をするため、ならびにユーザーが支払いを行うために必要な情報を含みます。<br />
                    ・ユーザーによる本サービスの利用に関して作成と維持が必要となる取引情報。<br />
                    ②安全保護のための行政機関、捜査機関への情報開示<br />
                    当社は、ユーザー又は第三者の安全が脅かされる場合に、行政機関、捜査機関へのデータ開示行為を含め、個人情報を処理することができるものとします。<br />
                    ③サービスの提供に付随した情報の収集・使用<br />
                    当社は、当社の正当な利益を保護するために必要な範囲に限り、個人情報を収集し、使用します。これには、次の目的で行われる情報の収集及び使用を含みます。<br />
                    ・当社ユーザーの安全を維持ないし改善する目的。<br />
                    ・本サービスに関する不正行為を防止及び検出し、これに対応する目的。<br />
                    ・犯罪行為又は公共の安全に対する脅威に関して、行政機関、捜査機関の当局者に情報提供する目的。<br />
                    ・カスタマーサポートを提供する目的。<br />
                    ・研究及び分析を行う目的。<br />
                    ・ダイレクトマーケティングを行う目的。<br />
                    ・当社の利用規約を執行する目的。<br />
                    ④第三者又は公共の利益を保護するための情報の収集・使用<br />
                    当社は、第三者又は公共の利益を保護するために必要な範囲において、個人情報を収集し、使用します。<br />
                    上記に加え、当社は、重要な公共の利益に関して必要がある場合、適用法に基づき情報を収集し使用することができます。<br />
                    ⑤同意に基づく情報の収集と使用<br />
                    当社は、ユーザーの同意に基づきユーザーの情報を収集し、使用することができます。同意は、いつでも取り消すことができます。同意を取り消した場合、ユーザーは、当社がユーザーの同意に基づき収集又は使用した情報が必要となるサービス又は機能を利用することができなくなります。<br />
                    <br />
                    <br />
                    ＜改訂履歴＞<br />
                    <br />
                    2022年2月7日制定
                </p>

              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

TermsPage.propTypes = propTypes

export default TermsPage

export const pageQuery = graphql`
  query PrivacyJaQuery {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`